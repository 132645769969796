<template>
    <div class="FMcontainer" :class='$mq'>
        <div class="FMimgcontcont" :class='$mq'>
            <div class="FMimgcont" :class='$mq'>
                <KImage :src="require('@/assets/miniland/check-list.png')"  alt="Art doll" width="100%"/>
            </div>
        <div class="FMlight"></div>
        </div>

        <div class="FMtext" :class='$mq'>
            <h1 :class='$mq'>Как слепить красивое лицо</h1>
            <ul>
                <li>Как сделать разметку лица, чтобы определеить положение носа, губ, глаз</li>
                <li>Как слепить основные объемы</li>
                <li>Как глазами передать эмоцию</li>
            </ul>
            <KRoundedButton @click="login" class="FMbutton" :class='$mq' color="#F92560" text-color="white" >
                Получить доступ
                <template v-slot:icon>
                    <KIHandFlower icon-color="#FFF" width="40pt" height="40pt"/>
                </template>
            </KRoundedButton>
        </div>
    </div>    
</template>

<script>
import KImage from "../../Widgets/KImage";
import KRoundedButton from "../../Widgets/KRoundedButton";
import KIHandFlower from "../../Widgets/Icons/KIHandFlower";
import { getEmailFaceCheck } from "@/service/api"

export default {
    components: {
        KImage,
        KRoundedButton,
        KIHandFlower
    },
    methods: {
        login() {
            if (!this.$auth.isAuthenticated) {
                this.$auth.loginWithRedirect();
            } else {
                this.$router.push('/mycourses/face-emotions/1')
            }
        }
    }
}
</script>

<style scoped>
  .FMlight {
    position: absolute;
    width: 1000px;
    height: 1000px;
    background: radial-gradient(circle 500px, rgba(189,79,71,0.3), transparent 100%);
    top: -50%;
    right: -50%;
    z-index: 0;
  }

    .FMcontainer {
        margin: 50pt auto 80pt auto;
        display: flex;
        justify-content: center;
      align-items: center;
        padding: 0 20px;
        max-width: 920px;
        font-size: 0.9em;
    }
        .FMcontainer.tablet {
            margin: 30pt auto 50pt auto;
        }
        .FMcontainer.halftablet, .FMcontainer.mobile {
            flex-wrap: wrap;
            margin: 20pt auto 40pt auto;
        }

    .FMtext {
        position: relative;
        z-index: 1;
        max-width: 400px;
    }
        .FMtext.tablet{
            max-width: 330px;
        }
        .FMtext.mobile {
            max-width: 250px;
        }
    .FMtext ul {
        margin-left: 10pt;
        margin-bottom: 20pt;
    }
    .FMtext ul li {
        padding: 0.5em 0 0.5em 3em;
      margin-bottom: 1em;
        background: url("~@/assets/miniland/check.svg") no-repeat;
        background-size: 18pt;
        background-position-Y: center;
        line-height: 1.1;
    }
        .FMtext.mobile ul {
            margin-bottom: 1em;
        }

    h1  {
        font-size: 3em;
        line-height: 0.8;
        margin-bottom: 20pt;
    }
        h1.tablet{
            font-size: 2.5em;
        }
        h1.mobile {
            font-size: 1.8em;
            text-align: center;
            margin-bottom: 0.3em;
        }
    h1 span {
        font-size: 2.6em;
    }


    .FMimgcontcont {
        display: block;
        width: 50%;
        position: relative;
    }
        .FMimgcontcont.tablet{
            width: 40%;
        }
        .FMimgcontcont.halftablet {
            width: 70%;
        }
        .FMimgcontcont.mobile {
            width: 100%;
            margin-bottom: 20pt;
        }

    .FMimgcont {
      position: relative;
      z-index: 1;
    }

    .FMbutton {
        width: 100%;
        box-sizing: border-box;
        padding: 5px 20px;
    }
        .FMbutton.mobile {
            font-size: 1em;
        }
        .FMbutton.mobile span.icon {
            margin-left: 10pt!important;
        }
</style>